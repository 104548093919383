import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { NavbarComponent } from './components/pageComps/navbar/navbar.component';
import { FooterComponent } from './components/pageComps/footer/footer.component';
import { LandingCarouselComponent } from './components/homepage/landing-carousel/landing-carousel.component';
import { FloatingSidebarComponent } from './components/pageComps/floating-sidebar/floating-sidebar.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxImageZoomModule } from 'ngx-image-zoom';

import { HomepageComponent } from './routes/homepage/homepage.component';
import { ProductsComponent } from './routes/products/products.component';
import { ProcessComponent } from './routes/process/process.component';
import { ArtistsComponent } from './routes/artists/artists.component';
import { LocationsComponent } from './routes/locations/locations.component';
import { TestComponent } from './test/test.component';
import { TextDotsComponent } from './components/process/text-dots/text-dots.component';
import { DotScrollerComponent } from './components/pageComps/dot-scroller/dot-scroller.component';
import { FooterBackgroundComponent } from './components/pageComps/footer-background/footer-background.component';
import { ProcessSliderComponent } from './components/process/process-slider/process-slider.component';
import { EventUpdatesComponent } from './components/homepage/event-updates/event-updates.component';
import { NeedleAnimationComponent } from './components/process/needle-animation/needle-animation.component';
import { ProcessLoomComponent } from './components/process/process-loom/process-loom.component';
import { LocationMapComponent } from './components/location/location-map/location-map.component';
import { TheProcessComponent } from './routes/the-process/the-process.component';
import { ProcessButtonsAnimationComponent } from './components/process/process-buttons-animation/process-buttons-animation.component';
import { ProcessClothComponent } from './components/process/process-cloth/process-cloth.component';
import { ProcessParllaxBgComponent } from './components/process/process-parllax-bg/process-parllax-bg.component';
import { ArtistComponentComponent } from './components/artist/artist-component/artist-component.component';
import { ProductCardComponent } from './components/products/product-card/product-card.component';
import { ProductModalComponent } from './components/products/product-modal/product-modal.component';
import { BlogBlockComponent } from './components/blogs/blog-block/blog-block.component';
import { BlogsComponent } from './routes/blogs/blogs.component';
import { ProductCardMobileComponent } from './components/products/product-card-mobile/product-card-mobile.component';
import { ArtistMobileComponent } from './components/artist/artist-mobile/artist-mobile.component';




@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    LandingCarouselComponent,
    FloatingSidebarComponent,
    HomepageComponent,
    ProductsComponent,
    ProcessComponent,
    ArtistsComponent,
    LocationsComponent,
    TestComponent,
    TextDotsComponent,
    DotScrollerComponent,
    FooterBackgroundComponent,
    ProcessSliderComponent,
    EventUpdatesComponent,
    NeedleAnimationComponent,
    ProcessLoomComponent,
    LocationMapComponent,
    TheProcessComponent,
    ProcessButtonsAnimationComponent,
    ProcessClothComponent,
    ProcessParllaxBgComponent,
    ArtistComponentComponent,
    ProductCardComponent,
    ProductModalComponent,
    BlogBlockComponent,
    BlogsComponent,
    ProductCardMobileComponent,
    ArtistMobileComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    SlickCarouselModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyARxY64WFB2VJ7Z7DYSYJgojnu4_RXEqFI'
    }),
    NgxPaginationModule,
    MDBBootstrapModule.forRoot(),
    NgxImageZoomModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
