import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-process-slider',
  templateUrl: './process-slider.component.html',
  styleUrls: ['./process-slider.component.scss']
})
export class ProcessSliderComponent implements OnInit {

  @Input() imgArray: string[];
  @Input() alignment: string;
  @Input() moveTheCard: boolean;
  @Input() sliderText: string;

  @ViewChild('card1', {static: false}) card1: ElementRef;
  @ViewChild('card2', {static: false}) card2: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  hideImage(img1: HTMLImageElement, img2: HTMLImageElement, img3: HTMLImageElement, input: string) {
    if (input === 'left') {
      img1.style.clipPath = 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)';
      img2.style.clipPath = 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)';
      img3.style.clipPath = 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)';
    } else if (input === 'middle') {
      img1.style.clipPath = 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)';
      img2.style.clipPath = 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)';
      img3.style.clipPath = 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)';
    } else if (input === 'right') {
      img1.style.clipPath = 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)';
      img2.style.clipPath = 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)';
      img3.style.clipPath = 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)';
    }
  }

  showImage(img1: HTMLImageElement, img2: HTMLImageElement, img3: HTMLImageElement) {
    img1.style.clipPath = 'polygon(0% 0%, 31% 0%, 31% 100%, 0% 100%)';
    img2.style.clipPath = 'polygon(34.5% 0%, 65.5% 0%, 65.5% 100%, 34.5% 100%)';
    img3.style.clipPath = 'polygon(69% 0%, 100% 0%, 100% 100%, 69% 100%)';
  }

  next(img1: HTMLImageElement, img2: HTMLImageElement, img3: HTMLImageElement) {

    img1.style.transition = 'clip-path 0s';
    img1.style.clipPath = 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)';
    if (this.imgArray.length > 3) {
      img1.src = this.imgArray[3];
    } else {
      img1.src = this.imgArray[0];
    }

    const current = this;
    setTimeout( function() {
      img1.style.transition = 'clip-path 1s';
      img2.style.clipPath = 'polygon(0% 0%, 31% 0%, 31% 100%, 0% 100%)';
      img3.style.clipPath = 'polygon(34.5% 0%, 65.5% 0%, 65.5% 100%, 34.5% 100%)';
      img1.style.clipPath = 'polygon(69% 0%, 100% 0%, 100% 100%, 69% 100%)';
    }, 10);

    setTimeout( function() {
      img1.style.transition = 'clip-path 0s';
      img2.style.transition = 'clip-path 0s';
      img3.style.transition = 'clip-path 0s';
    }, 2010);

    setTimeout ( function() {
      const popped = current.imgArray.shift();
      current.imgArray.push(popped);
      img1.src = current.imgArray[0];
      img1.style.clipPath = 'polygon(0% 0%, 31% 0%, 31% 100%, 0% 100%)';
      img2.style.clipPath = 'polygon(34.5% 0%, 65.5% 0%, 65.5% 100%, 34.5% 100%)';
      img3.style.clipPath = 'polygon(69% 0%, 100% 0%, 100% 100%, 69% 100%)';
    }, 2020);

    setTimeout ( function() {
      img1.style.transition = 'clip-path 1s';
      img2.style.transition = 'clip-path 1s';
      img3.style.transition = 'clip-path 1s';
    }, 2050);

  }

  prev(img1: HTMLImageElement, img2: HTMLImageElement, img3: HTMLImageElement) {

    img3.style.transition = 'clip-path 0s';
    img3.style.clipPath = 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)';
    img3.src = this.imgArray[this.imgArray.length - 1];

    const current = this;
    setTimeout( function() {
      img3.style.transition = 'clip-path 1s';
      img1.style.clipPath = 'polygon(34.5% 0%, 65.5% 0%, 65.5% 100%, 34.5% 100%)';
      img2.style.clipPath = 'polygon(69% 0%, 100% 0%, 100% 100%, 69% 100%)';
      img3.style.clipPath = 'polygon(0% 0%, 31% 0%, 31% 100%, 0% 100%)';
    }, 10);

    setTimeout( function() {
      img1.style.transition = 'clip-path 0s';
      img2.style.transition = 'clip-path 0s';
      img3.style.transition = 'clip-path 0s';
    }, 2010);

    setTimeout ( function() {
      const popped = current.imgArray.pop();
      current.imgArray.unshift(popped);
      img3.src = current.imgArray[2];
      img1.style.clipPath = 'polygon(0% 0%, 31% 0%, 31% 100%, 0% 100%)';
      img2.style.clipPath = 'polygon(34.5% 0%, 65.5% 0%, 65.5% 100%, 34.5% 100%)';
      img3.style.clipPath = 'polygon(69% 0%, 100% 0%, 100% 100%, 69% 100%)';
    }, 2020);

    setTimeout ( function() {
      img1.style.transition = 'clip-path 1s';
      img2.style.transition = 'clip-path 1s';
      img3.style.transition = 'clip-path 1s';
    }, 2050);

  }

  ngOnChanges() {
    if (this.moveTheCard) {
      if (this.card1) {
        this.card1.nativeElement.style.transform = 'translateX(50px)';
      }
      if (this.card2) {
        this.card2.nativeElement.style.transform = 'translateX(-50px)';
      }
    } else {
      if (this.card1) {
        this.card1.nativeElement.style.transform = 'translateX(-50px)';
      }
      if (this.card2) {
        this.card2.nativeElement.style.transform = 'translateX(50px)';
      }
    }
  }

}
