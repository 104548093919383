import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-process-cloth',
  templateUrl: './process-cloth.component.html',
  styleUrls: ['./process-cloth.component.scss']
})
export class ProcessClothComponent implements OnInit {

  @HostListener ('window: scroll')
  scrollHandler() {
    // tslint:disable-next-line: max-line-length
    document.getElementById('processCloth').style.transform = 'translateY(' + (250 - document.getElementById('processCloth').getBoundingClientRect().top / 3) + 'px)';
  }

  constructor() { }

  ngOnInit() {
  }

}
