import { Component, OnInit, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { AuthorizationService } from '../../services/authorization.service';
import { ProductModalComponent } from 'src/app/components/products/product-modal/product-modal.component';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnDestroy, OnInit {
  public storedData: any;
  p: any;
  subscription: any;
  modalInfo: any;

  @ViewChild (ProductModalComponent, {static: false}) productModal: ProductModalComponent;

  constructor(private authService: AuthorizationService) {
    if (document.getElementById('navbar')) { document.getElementById('navbar').classList.add('sticky'); }
  }

  ngOnInit() {
    this.subscription = this.authService.filter.subscribe((data) => {
      this.getProductData(data);
    });
  }

  productClicked(item: any) {
    this.modalInfo = item;
    this.productModal.launchModal();
  }

  getProductData(filter?: string) {
    this.authService.get('wp-json/wp/v2/products?per_page=54').subscribe((d: any) => {
      if (filter) {
        this.storedData = d.filter((product: any) => {
          if (product.acf.category === filter) {
            return true;
          } else {
            return false;
          }
        }).map((product: any) => {
          return {
            artist: product.acf.artists,
            title: product.title.rendered,
            productID: product.id,
            productCategory: product.acf.category,
            price: product.acf.price,
            mrp: product.acf.mrp,
            quantity: product.acf.quantity,
            colors: product.acf.color,
            imageURL: product.acf.display_image,
            imageList: this.getImages(Object.values(product.acf.images)),
            description: product.acf.description,
            info: product.acf.short_info
          };
        });
      } else {
        this.storedData = d.map((product: any) => {
          return {
            artist: product.acf.artists,
            title: product.title.rendered,
            productID: product.id,
            productCategory: product.acf.category,
            price: product.acf.price,
            mrp: product.acf.mrp,
            quantity: product.acf.quantity,
            colors: product.acf.color,
            imageURL: product.acf.display_image,
            imageList: this.getImages(Object.values(product.acf.images)),
            description: product.acf.description,
            info: product.acf.short_info
          };
        });
      }
      this.p = 0;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  filterProduct(input?: string) {
    if (input) {
      this.authService.filter.next(input);
    } else {
      this.authService.filter.next('');
    }
  }

  getImages(imageArray: any[]) {
    const images = [];
    imageArray.forEach((image) => {
      if (image !== false) {
        images.push(image);
      }
    });
    return images;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
