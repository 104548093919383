import { Component, OnInit , OnDestroy, ViewChild} from '@angular/core';
import { trigger, transition, useAnimation, state, style } from '@angular/animations';
import { fadeInUp, fadeOutDown, pulse } from 'ng-animate';
import { TextDotsComponent } from 'src/app/components/process/text-dots/text-dots.component';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss'],
  animations: [
    // trigger('fadeInUp', [state('open'), style({})], [transition('* => *', useAnimation(fadeInUp, {
    //   params: { timing: 0.5, delay: 0 }
    // }))]),
    // trigger('fadeOutDown', [transition('* => *', useAnimation(fadeOutDown, {
    //   params: { timing: 0.5, delay: 0 }
    // }))])
    trigger('fadeInUp', [
      state('true', style({})),
      state('false', style({})),
      transition('false => true', useAnimation(pulse, { params: {timing: 0.5, delay: 0}})),
      // transition('true => false', useAnimation(fadeOutDown, { params: {timing: 0.5, delay: 0}}))
    ])
  ],
})
export class ProcessComponent implements OnInit {
  fadeInUp: any;
  listedNames: string[] = ['Dyeing', 'Winding', 'Warping', 'Denting-Drafting', 'Weaving'];
  listIds: string[] = ['section1', 'section3', 'section5', 'section6', 'section7'];
  // tslint:disable-next-line: max-line-length
  imgArrayDyeing = ['../../assets/img/processPage/Dyeing/1.png', '../../assets/img/processPage/Dyeing/2.png', '../../assets/img/processPage/Dyeing/3.png', '../../assets/img/processPage/Dyeing/4.png'];
  // tslint:disable-next-line: max-line-length
  imgArrayWinding = ['../../assets/img/processPage/Winding/1.png', '../../assets/img/processPage/Winding/2.png', '../../assets/img/processPage/Winding/3.png'];
  // tslint:disable-next-line: max-line-length
  imgArrayWarping = ['../../assets/img/processPage/Warping/1.png', '../../assets/img/processPage/Warping/2.png', '../../assets/img/processPage/Warping/3.png', '../../assets/img/processPage/Warping/4.png'];
  // tslint:disable-next-line: max-line-length
  imgArrayDentingDrafting = ['../../assets/img/processPage/dentingDrafting/1.png', '../../assets/img/processPage/dentingDrafting/2.png', '../../assets/img/processPage/dentingDrafting/3.png', '../../assets/img/processPage/dentingDrafting/4.png', '../../assets/img/processPage/dentingDrafting/5.png'];
  // tslint:disable-next-line: max-line-length
  imgArrayWeaving = ['../../assets/img/processPage/Weaving/1.png', '../../assets/img/processPage/Weaving/2.png', '../../assets/img/processPage/Weaving/3.png', '../../assets/img/processPage/Weaving/4.png'];
// tslint:disable-next-line: max-line-length
dotText = [
  'Dyeing in textiles is a process in which color is transferred to a finished textile or textile material (like fibers and yarns) to add permanent and long-lasting color. In Kenjakura it was done by hand.',
  'It is a process of transferring yarns from ring bobbins, hanks, cones etc into a convenient form of long length of yarns.',
  'Preparing the weavers beam to be set up on the weaving machine.',
  'Drawing the warp thread through the dent as required by reed plan and this determines more accurately the width of the fabric and then drafting is the selection process of heald frames for individual warp threads according to the design.',
  'Weaving is a method of textile production in which two distinct sets of yarns or threads are interlaced at right angles to form a fabric or cloth. The longitudinal threads are called the warp and the lateral threads are the weft.'
];
// dotOrientation = 'left';
animationStack = ['../../assets/img/1.png',  '../../assets/img/2.png', '../../assets/img/3.png'];
left = 'left';
right = 'right';

dotColor1 = '#688f9d';
dotColor2 = 'lightgrey';
dotColor3 = '#ffbbac';
dotColor4 = '#7f7f7f';
textColor1 = 'white';
textColor2 = 'black';
textColor3 = 'black';
textColor4 = 'white';


canBounce = [true, false, false, false, false, false, false];
canShowDots = [false, false, false, false, false, false, false];
idList = ['section1', 'section2', 'section3', 'section4', 'section5', 'section6', 'section7'];
topBottomWindow = 0;

@ViewChild(TextDotsComponent, {static: false}) textDot: TextDotsComponent;


constructor() {
  document.getElementById('navbar').classList.add('sticky');
}

scroll = (): void => {
  for (const id of this.idList) {
    if (document.getElementById(id)) {
      if (document.getElementById(id).getBoundingClientRect().top <= (2 * window.innerHeight / 3)) {
        this.handleBounce(id);
      }
      if (
        (document.getElementById(id).getBoundingClientRect().top > (this.topBottomWindow - 100)) &&
        (document.getElementById(id).getBoundingClientRect().top < this.topBottomWindow + 500)
      ) {
        this.canShowDots[this.idList.indexOf(id)] = true;
      } else {
        this.canShowDots[this.idList.indexOf(id)] = false;
      }
    }
  }
}

handleBounce(id: string) {
  const bounceId = this.idList.indexOf(id);
  // this.canBounce = [true, false, false, false, false, false];
  let j = 0;
  while (j < bounceId) {
    this.canBounce[j + 1] = true;
    j++;
  }
  this.hideSections();
}


hideSections() {
  for (const id of this.idList) {
    if (this.canBounce[this.idList.indexOf(id)] === false && id !== 'section2' && id !== 'section4') {
      document.getElementById(id).style.opacity = '0';
    } else {
      document.getElementById(id).style.opacity = '1';
    }
  }
}



ngOnInit() {
  window.addEventListener('scroll', this.scroll, true);
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
  this.topBottomWindow = (window.innerHeight - 700) / 2;
}



}
