import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-needle-animation',
  templateUrl: './needle-animation.component.html',
  styleUrls: ['./needle-animation.component.scss']
})
export class NeedleAnimationComponent implements OnInit {
    scrollPos: any;
  scroll = (): void => {
    if (document.getElementById('needleImage')) {
      document.getElementById('needleImage').setAttribute('src', '../../../assets/img/needle/' +
      (11 - this.imageIndex(document.getElementById('needleImage').getBoundingClientRect().top))
    + '.png');
    }
  }

  constructor() {

   }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
  }

  imageIndex(distanceToTop: number): number {
    const windowSize = (window.innerHeight - 200) / 12;
    if ((distanceToTop / windowSize) > 0) {
      if ((distanceToTop / windowSize) < 11) {
        return Math.floor(distanceToTop / windowSize);
      } else {
        return 11;
      }
    } else {
      return 0;
    }
  }

}
