import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  title = 'HAHA';
  myThumbnail = '../../assets/img/charkha/wheel-with-strings.png';
  myFullresImage = '../../assets/img/charkha/wheel-with-strings.png';
  constructor() {}

  log() {
    // document.getElementsByClassName('ngxImageZoomThumbnail')[0].clientHeight = 300;
    (document.getElementsByClassName('ngxImageZoomThumbnail')[0] as HTMLImageElement).style.height = '300px';
  }

  ngOnInit() {
  }



}
