import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public currentRoute: string;

  constructor(private router: Router, location: Location) {
    router.events.subscribe(val => {
      if (location.path() !== '') {
        this.currentRoute = location.path();
      } else {
        this.currentRoute = 'home';
      }
    });
  }
}
