import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-process-loom',
  templateUrl: './process-loom.component.html',
  styleUrls: ['./process-loom.component.scss']
})
export class ProcessLoomComponent implements OnInit {

  @ViewChild('wheel', {static: false}) wheel: ElementRef;

  @HostListener ('window: scroll')
  scrollHandler() {
    this.rotateWheel(document.getElementById('processLoomParent').getBoundingClientRect().top)
  }

  constructor() { }

  ngOnInit() {
  }

  rotateWheel(scrollValue: number) {
    this.wheel.nativeElement.style.transform = 'rotate(' + (scrollValue / 3) + 'deg)';
  }

}
