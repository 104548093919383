import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-artist-component',
  templateUrl: './artist-component.component.html',
  styleUrls: ['./artist-component.component.scss']
})
export class ArtistComponentComponent implements OnInit {

  @Input() alignment: string;
  @Input() artist: any;
  artistName: string;
  artistDetails: string;
  artistQuote: string;
  artistImage: string;

  constructor() {
    // this.artistName = 'Artist Name';
    // // tslint:disable-next-line: max-line-length
    // this.artistDetails = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta adipisci ipsum velit dolorum facilis optio. Animi saepe placeat mollitia necessitatibus architecto, repellendus asperiores iste. Ducimus deserunt minima porro quo repellendus? Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis veritatis quia numquam accusamus hic doloremque deserunt dolorum dolorem optio alias sunt cupiditate sapiente libero aliquid, maxime voluptate impedit corporis illo.';
    // this.artistQuote = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta adipisci ipsum velit';
    // this.artistImage = '../../../../assets/img/Artist_section_2.png';
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.artistDetails = this.artist.description,
    this.artistImage = this.artist.image,
    this.artistName = this.artist.name,
    this.artistQuote = this.artist.info;
  }

}
