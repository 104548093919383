import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './routes/homepage/homepage.component';
import { ProductsComponent } from './routes/products/products.component';
import { ProcessComponent } from './routes/process/process.component';
import { ArtistsComponent } from './routes/artists/artists.component';
import { LocationsComponent } from './routes/locations/locations.component';
import { BlogsComponent } from './routes/blogs/blogs.component';
import { TestComponent } from './test/test.component';

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'process', component: ProcessComponent },
  { path: 'artists', component: ArtistsComponent },
  { path: 'locations', component: LocationsComponent },
  { path: 'blogs', component: BlogsComponent },
  { path: 'test', component: TestComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
