import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-landing-carousel',
  templateUrl: './landing-carousel.component.html',
  styleUrls: ['./landing-carousel.component.scss']
})
export class LandingCarouselComponent implements OnInit {

  imagesObject: {image: string, caption: string}[] = [];

  constructor(
    private authService: AuthorizationService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.authService.get('wp-json/wp/v2/bannerImage').subscribe(
      (data: any) => {
        data.forEach(element => {
          this.imagesObject.push({
            image: element.acf.image,
            caption: element.acf.caption
          });
        });
      }
    );
  }

  sanitizeImage(input: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${input})`);
  }

}
