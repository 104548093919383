import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-process-buttons-animation',
  templateUrl: './process-buttons-animation.component.html',
  styleUrls: ['./process-buttons-animation.component.scss']
})
export class ProcessButtonsAnimationComponent implements OnInit {

  @HostListener ('window: scroll')
  scrollHandler() {
    // tslint:disable-next-line: max-line-length
    document.getElementById('button').style.transform = 'rotate(' + (document.getElementById('button').getBoundingClientRect().top / 20) + 'deg)';
  }

  constructor() { }

  ngOnInit() {
  }

}
