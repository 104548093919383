import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { makeRe } from 'minimatch';
import { AuthorizationService } from 'src/app/services/authorization.service';
declare let L;

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss']
})
export class LocationMapComponent implements OnInit {
  @ViewChild('modalButton', {static: false}) modalButton: ElementRef;
  private accesstoken = 'pk.eyJ1IjoiZGF0YXN1dHJhbSIsImEiOiJjamlwb3Zham8wbXd0M3F0OThwMnVxdHppIn0.NMWjnn9UFsWhrntsqQxa9Q';
  private listId = ['mapbox.dark', 'mapbox.light', 'mapbox.satellite',
    'mapbox.streets'];
  private tileId = 0;
  modalInfo: any;
  locationData: any[];
  constructor(private authService: AuthorizationService) {
    this.authService.get('wp-json/wp/v2/location').subscribe((d) => {
      this.storeData(d);
      this.addMarkerToMap();
    });
  }
  private centre = L.latLng(23.392110647685083, 86.96480152488402);
  private zoom = 9;
  private map: any;
  private tile: any;
  private markerClicked: number;
  private markerArray = [];

  kenjakuraIcon = L.icon({
    iconUrl: '/assets/img/location/kenjakura.png', iconSize: [65, 65], shadowSize: [50, 64],
    iconAnchor: [22, 94], shadowAnchor: [4, 62], popupAnchor: [-3, -76]
  });
  otherClosedIcon = L.icon({
    iconUrl: '/assets/img/location/other-closed.png', iconSize: [38, 38], shadowSize: [50, 64],
    iconAnchor: [22, 94], shadowAnchor: [4, 62], popupAnchor: [-3, -76]
  } );
  otherOpenIcon = L.icon({
    iconUrl: '/assets/img/location/other-open.png', iconSize: [38, 38], shadowSize: [50, 64],
    iconAnchor: [22, 94], shadowAnchor: [4, 62], popupAnchor: [-3, -76]
  } );
  handicraftClosedIcon = L.icon({
    iconUrl: '/assets/img/location/handicraft-closed.png', iconSize: [38, 38], shadowSize: [50, 64],
    iconAnchor: [22, 94], shadowAnchor: [4, 62], popupAnchor: [-3, -76]
  });
  handicraftOpenIcon = L.icon({
    iconUrl: '/assets/img/location/handicraft-open.png', iconSize: [38, 38], shadowSize: [50, 64],
    iconAnchor: [22, 94], shadowAnchor: [4, 62], popupAnchor: [-3, -76]
  });
  handloomClosedIcon = L.icon({
    iconUrl: '/assets/img/location/handloom-closed.png', iconSize: [38, 38], shadowSize: [50, 64],
    iconAnchor: [22, 94], shadowAnchor: [4, 62], popupAnchor: [-3, -76]
  });
  handloomOpenIcon = L.icon({
    iconUrl: '/assets/img/location/handloom-open.png', iconSize: [38, 38], shadowSize: [50, 64],
    iconAnchor: [22, 94], shadowAnchor: [4, 62], popupAnchor: [-3, -76]
  });

  ngOnInit() {
    const mapbox_url = 'https://api.mapbox.com/styles/v1/mapbox/streets-v8/tiles/{z}/{x}/{y}?access_token={accessToken}';
    const accesstoken = 'pk.eyJ1IjoiZGF0YXN1dHJhbSIsImEiOiJjamlwb3Zham8wbXd0M3F0OThwMnVxdHppIn0.NMWjnn9UFsWhrntsqQxa9Q';
    this.map = L.map('map', { zoom: this.zoom, center: this.centre, zoomControl: false });
    // tslint:disable-next-line: max-line-length
    this.tile = L.tileLayer(mapbox_url, { maxZoom: 18, attribution: '', minZoom: 0, noWrap: false, accessToken: accesstoken, id: 'mapbox.light' }).addTo(this.map);
   
  }

  storeData(inputData: any) {
    this.locationData = inputData.map(location => {
      return {
        contact: location.acf.contact_number,
        category: location.acf.category,
        description: location.acf.description,
        images: Object.values(location.acf.images).filter(image => {
          if (image !== false) {
            return true;
          } else {
            return false;
          }
        }),
        intro: location.acf.introduction,
        latitude: location.acf.location.lat,
        longitude: location.acf.location.lng,
        name: location.acf.location_name,
        website: location.acf.website
      };
    });
  }

  addMarkerToMap() {
    this.locationData.forEach((location, index) => {
      const current = this;
      let anL: any;
      if (location.category === 'Other') {
        anL = L.marker([location.latitude, location.longitude], { icon: this.otherClosedIcon });
      } else if (location.category === 'Handicraft Cluster') {
        anL = L.marker([location.latitude, location.longitude], { icon: this.handicraftClosedIcon });
      } else if (location.category === 'Handloom Cluster') {
        anL = L.marker([location.latitude, location.longitude], { icon: this.handloomClosedIcon });
      } else if (location.category === 'Kenjakura') {
        anL = L.marker([location.latitude, location.longitude], { icon: this.kenjakuraIcon });
      }
      anL.addTo(this.map);
      anL.bindPopup(location.name);
      anL.on('click', function(e)  { current.buttonClicked(index); });
      anL.on('mouseover', function(e) { this.openPopup(); });
      anL.on('mouseout', function(e) { this.closePopup(); });
      this.markerArray.push(anL);
    });
  }

  tileToggle() {
    this.tileId += 1;
    if (this.tileId >= this.listId.length) {
      this.tileId = 0;
    }
    this.map.removeLayer(this.tile);
    this.tile = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v8/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      maxZoom: 18, attribution: '', minZoom: 0, noWrap: false, accessToken: this.accesstoken, id: this.listId[this.tileId]
    });
    this.tile.addTo(this.map);
    
   

  }

  buttonClicked(val: any) {
    this.markerClicked = val;
    this.modalInfo = this.locationData[val];
    this.modalButton.nativeElement.click();
    for (let i = 0; i < this.locationData.length; i++ ) {
      if (this.locationData[i].category === 'Other') {
        this.markerArray[i].setIcon(this.otherClosedIcon);
      } else if (this.locationData[i].category === 'Handicraft Cluster') {
        this.markerArray[i].setIcon(this.handicraftClosedIcon);
      } else if (this.locationData[i].category === 'Handloom Cluster') {
        this.markerArray[i].setIcon(this.handloomClosedIcon);
      }
    }
    this.map.setZoom(10);
    this.map.panTo( new L.latLng(this.locationData[val].latitude, this.locationData[val].longitude));
    if (this.locationData[val].category === 'Other') {
      this.markerArray[val].setIcon(this.otherOpenIcon);
    } else if (this.locationData[val].category === 'Handicraft Cluster') {
      this.markerArray[val].setIcon(this.handicraftOpenIcon);
    } else if (this.locationData[val].category === 'Handloom Cluster') {
      this.markerArray[val].setIcon(this.handloomOpenIcon);
    }
  }

}
