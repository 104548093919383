import { Component, OnInit } from '@angular/core';
import { faPhoneAlt, faEnvelope, faMap } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  faPhoneAlt = faPhoneAlt;
  faEnvelope = faEnvelope;
  faMap = faMap;

  constructor() { }

  ngOnInit() { }

  toTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
