import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, AfterViewInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';


@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss']
})
export class ProductModalComponent implements OnChanges {

  @ViewChild('productModalLaunchButton', {static: false}) modalLaunch: ElementRef;
  @ViewChild('popUpModal', {static: false}) popUpModal: any;
  @ViewChild('basicModal', {static: false}) basicModal: any;

  @Input() product: any;

  imageArray: string[];
  selectedIndex = 0;
  enquiryForm: {name: string, email: string, mobile: string, message: string, productId: string} = {
    name: '', email: '', mobile: '', message: '', productId: ''
  };



  constructor(
    private authService: AuthorizationService
  ) { }

  launchModal() {
    if (this.modalLaunch) {
      this.modalLaunch.nativeElement.click();
    }
  }

  log() {
    setTimeout(() => {
      const list = (document.getElementsByClassName('ngxImageZoomThumbnail') as any);
      for (const image of list) {
        image.style.width = '300px';
      }
    }, 5);
  }


  changeIndex(i: number) {
    this.log();
    this.selectedIndex = i;
  }

  onSubmit() {
    this.enquiryForm.productId = this.product.productID;
    this.basicModal.hide();
    const body = {
      email: 'rcchbengal@gmail.com',
      emailTitle: 'Enquiry from Kenjakura',
      emailBody: `<h3>${this.enquiryForm.name} just made an enquiry<h3>`
        + `<p>Name: ${this.enquiryForm.name}</p>`
        + `<p>Email: ${this.enquiryForm.email}</p>`
        + `<p>Mobile: ${this.enquiryForm.mobile}</p>`
        + `<p>Message: ${this.enquiryForm.message}</p>`
        + `<p>Product ID: ${this.enquiryForm.productId}</p>`
    };

    this.authService.sendMail(body).subscribe(
      (data: any) => { this.popUp(); }
    );
  }

  popUp() {
    this.popUpModal.show();
    setTimeout(() => {
      this.popUpModal.hide();
    }, 3000);
  }

  viewSimilar() {
    this.authService.filter.next(this.product.productCategory);
    this.basicModal.hide();
  }


  ngOnChanges() {
    this.selectedIndex = 0;
    if (this.product) {
      if (this.product.imageList.length) {
        this.imageArray = this.product.imageList.concat(this.product.imageURL);
      } else {
        this.imageArray = [this.product.imageURL];
      }
      this.log();
    }
  }

}
