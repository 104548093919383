import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-event-updates',
  templateUrl: './event-updates.component.html',
  styleUrls: ['./event-updates.component.scss']
})
export class EventUpdatesComponent implements OnInit {
  wholeData: any[] = [];
  canShowPrevious: boolean;
  canShowNext: boolean;
  // tslint:disable-next-line: max-line-length
  cardText: { text: string, venue: string, date: string, description: string, images: string[], startDate: string, endDate: string }[] = [
    {
      text: '1 Lorem ipsum dolor sit amet',
      venue: 'Venue A',
      date: '21-01-2011',
      // tslint:disable-next-line: max-line-length
      description: 'consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolon.quis nostrud exercitation ullamco',
      images: ['', ''],
      startDate: '',
      endDate: ''
    },
    {
      text: '2 Lorem ipsum dolor sit amet',
      venue: 'Venue B',
      date: '21-01-2011',
      // tslint:disable-next-line: max-line-length
      description: 'consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolon.quis nostrud exercitation ullamco',
      images: ['', ''],
      startDate: '',
      endDate: ''
    },
    {
      text: '3 Lorem ipsum dolor sit amet',
      venue: 'Venue C',
      date: '21-01-2011',
      // tslint:disable-next-line: max-line-length
      description: 'consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolon.quis nostrud exercitation ullamco',
      images: ['', ''],
      startDate: '',
      endDate: ''
    },
    {
      text: '4 Lorem ipsum dolor sit amet',
      venue: 'Venue D',
      date: '21-01-2011',
      // tslint:disable-next-line: max-line-length
      description: 'consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolon.quis nostrud exercitation ullamco',
      images: ['', ''],
      startDate: '',
      endDate: ''
    },
  ];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: '',
    prevArrow: '',
    dots: false,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  storedData: any[];
  output: string[] = [];
  showModal: boolean;
  modalInfo: any;
  @ViewChild('card1', { static: false }) card1: ElementRef;
  @ViewChild('card2', { static: false }) card2: ElementRef;
  @ViewChild('card3', { static: false }) card3: ElementRef;
  @ViewChild('card4', { static: false }) card4: ElementRef;

  @HostListener('window: scroll')
  scrollHandler() {
    this.moveCards(document.getElementById('notifications-list').getBoundingClientRect().top);
  }
  hover(pos: any) {
    if (pos === 'card1') {
      this.output[3] = this.cardText[3].text;
    }
    if (pos === 'card2') {
      this.output[2] = this.cardText[2].text;
    }
    if (pos === 'card3') {
      this.output[1] = this.cardText[1].text;
    }
    if (pos === 'card4') {
      this.output[0] = this.cardText[0].text;
    }
  }
  mouseout(pos: any) {
    const b: any = '...Read More';
    const position = 148;
    if (pos === 'card1') {
      this.output[3] = [this.cardText[3].text.slice(0, position), b, this.cardText[3].text.slice(position)].join('   ');
    }
    if (pos === 'card2') {
      this.output[2] = [this.cardText[2].text.slice(0, position), b, this.cardText[2].text.slice(position)].join('   ');
    }
    if (pos === 'card3') {
      this.output[1] = [this.cardText[1].text.slice(0, position), b, this.cardText[1].text.slice(position)].join('   ');
    }
    if (pos === 'card4') {
      this.output[0] = [this.cardText[0].text.slice(0, position), b, this.cardText[0].text.slice(position)].join('   ');

    }
  }

  constructor(
    private authService: AuthorizationService
  ) {
    const b = '...Read More';
    const position = 148;
    this.output[0] = [this.cardText[0].text.slice(0, position), b, this.cardText[0].text.slice(position)].join('   ');
    this.output[1] = [this.cardText[1].text.slice(0, position), b, this.cardText[1].text.slice(position)].join('   ');
    this.output[2] = [this.cardText[2].text.slice(0, position), b, this.cardText[2].text.slice(position)].join('   ');
    this.output[3] = [this.cardText[3].text.slice(0, position), b, this.cardText[3].text.slice(position)].join('   ');
  }

  ngOnInit() {
    this.authService.get('wp-json/wp/v2/events').subscribe((d) => {
      this.storeData(d);
      this.wholeData = this.storedData;
      if (this.wholeData.length >= 4) {
        this.cardText = this.wholeData.slice(0, 4);
      } else {
        this.cardText = this.wholeData;
      }
      this.checkPreviousNext();
    });
  }


  slickInit(e) {
  }

  breakpoint(e) {
  }

  afterChange(e) {
  }

  beforeChange(e) {
  }

  storeData(inputData: any): void {
    this.storedData = inputData.map(eventD => {
      return {
        text: eventD.acf.brief_intro,
        description: eventD.acf.event_details,
        date: eventD.acf.event_date,
        startDate: eventD.acf.event_start_date,
        endDate: eventD.acf.event_end_date,
        venue: eventD.acf.event_venue,
        images: Object.values(eventD.acf.event_images).filter(image => {
          if (image !== false) {
            return true;
          } else {
            return false;
          }
        })
      };
    });
  }

  moveCards(distance: number) {

    if (this.returnY(Math.max(0, 800 - distance), 700)) {
      // tslint:disable-next-line: max-line-length
      if (this.card4) { this.card4.nativeElement.style.transform = 'translate3d(' + ((this.returnY((Math.max(0, (800 - distance))), 700)) as any) as string + 'px ,0,0)'; }
      const x = this.returnY((Math.max(0, (800 - distance))), 30);
      if (this.card1) { this.card1.nativeElement.style.transform = 'translate3d(' + x + 'px, ' + x + 'px, 0px)'; }
      if (this.card2) { this.card2.nativeElement.style.transform = 'translate3d(' + x + 'px, ' + x + 'px, 0px)'; }
      if (this.card3) { this.card3.nativeElement.style.transform = 'translate3d(' + x + 'px, ' + x + 'px, 0px)'; }
    }

    if (this.returnY(Math.max(0, 600 - distance), 500)) {
      // tslint:disable-next-line: max-line-length
      if (this.card3) { this.card3.nativeElement.style.transform = 'translate3d(' + ((this.returnY((Math.max(30, (600 - distance))), 500)) as any) as string + 'px , 30px, 0)'; }
      const x = this.returnY((Math.max(30, (600 - distance))), 60);
      if (this.card1) { this.card1.nativeElement.style.transform = 'translate3d(' + x + 'px, ' + x + 'px, 0px)'; }
      if (this.card2) { this.card2.nativeElement.style.transform = 'translate3d(' + x + 'px, ' + x + 'px, 0px)'; }
    }

    if (this.returnY(Math.max(0, 400 - distance), 300)) {
      // tslint:disable-next-line: max-line-length
      if (this.card2) { this.card2.nativeElement.style.transform = 'translate3d(' + ((this.returnY((Math.max(60, (400 - distance))), 300)) as any) as string + 'px , 60px, 0)'; }
      const x = this.returnY((Math.max(60, (400 - distance))), 90);
      if (this.card1) { this.card1.nativeElement.style.transform = 'translate3d(' + x + 'px, ' + x + 'px, 0px)'; }
    }


  }

  returnY(canMove: number, maxY: number) {
    if (canMove < maxY && canMove > 0) {
      return canMove;
    } else if (canMove > maxY && canMove > 0) {
      return maxY;
    } else {
      return 0;
    }
  }

  cardClicked(item: any) {
    this.modalInfo = item;
  }

  modalClosed() {
    this.showModal = false;
  }

  checkPreviousNext() {
    console.log(this.storedData);
    if (this.storedData.length <= 4) {
      this.canShowNext = false;
      this.canShowPrevious = false;
    } else {
      if (this.storedData.indexOf(this.cardText[0]) === 0) {
        this.canShowNext = false;
      } else {
        this.canShowNext = true;
      }
      if (this.storedData.indexOf(this.cardText[3]) === (this.storedData.length - 1)) {
        this.canShowPrevious = false;
      } else {
        this.canShowPrevious = true;
      }
    }
  }

  showPreviousEvent() {
    const theIndex = this.storedData.indexOf(this.cardText[3]);
    this.cardText = this.storedData.slice(theIndex - 2, theIndex + 2);
    this.checkPreviousNext();
  }

  showNextEvent() {
    const theIndex = this.storedData.indexOf(this.cardText[0]);
    this.cardText = this.storedData.slice(theIndex - 1, theIndex + 3);
    this.checkPreviousNext();
  }

}
