import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnChanges {

  @Input() product: any;

  constructor(
    private authService: AuthorizationService
  ) { }

  ngOnChanges() {
  }

}
