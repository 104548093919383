import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  constructor() {
    document.getElementById('navbar').classList.add('sticky');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnInit() {
  }

}
