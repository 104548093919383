import { Component, OnInit, OnChanges, Input, ViewChild, ElementRef } from '@angular/core';





@Component({
  selector: 'app-text-dots',
  templateUrl: './text-dots.component.html',
  styleUrls: ['./text-dots.component.scss']
})
export class TextDotsComponent implements OnChanges {
  @Input()dotText: string;
  @Input()toggleTheDot: boolean;
  @Input()color: string;
  @Input()textColor: string;
  @ViewChild('textContainer', {static: true}) textContainer1: ElementRef;
  @ViewChild('textFill', {static: false}) textFill1: ElementRef;
  showText = false;
  step = false;
  count: any;
  settimeout: any;
  hover() {
    this.step = true;
    const current = this;
    this.settimeout = setTimeout( () => {
      if (current.textContainer1.nativeElement.classList[0] === 'expandDots') { this.showText = true; }
    }, 400 );
  }
  mouseout() {
    clearTimeout(this.settimeout);
    setTimeout( () => {this.showText = false; }, 20);
    setTimeout( () => {this.step = false; }, 30);

  }

   constructor() {
    //  this.toggleTextBox();
  }

  ngOnChanges() {
    if (this.toggleTheDot && this.toggleTheDot === true) {
      this.hover();
    } else if (this.toggleTheDot === false) {
      this.mouseout();
    }
  }


}
