import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../services/authorization.service';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss']
})
export class ArtistsComponent implements OnInit {
  artistData: Array<any>;

  left = 'left';
  right = 'right';

  currentPage = 1;
  pageSize = 3;
  total = 30;
  config: any;
  lastPageIndex: number;

  canShowSecondArtist: boolean;
  canShowThirdArtist: boolean;

  constructor(private authService: AuthorizationService) {

    document.getElementById('navbar').classList.add('sticky');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    this.authService.get('wp-json/wp/v2/artists')
      .subscribe(data => {
        this.extractData(data);
      });
  }

  ngOnInit() {
  }

  calculateAlignment(input: any) {
    const index = this.artistData.indexOf(input) + this.config.currentPage;
    if (index % 2 === 1) {
      return 'left';
    } else {
      return 'right';
    }
  }

  extractData(inputData: any) {
    this.artistData = inputData.map(artist => {
      return {
        name: artist.title.rendered,
        image: artist.acf.image,
        description: artist.acf.description,
        info: artist.acf.personal_info,
        id: artist.id
      };
    });
    this.config = {
      itemsPerPage: 3,
      currentPage: 1,
      totalItems: this.artistData.length
    };
    this.canArtistsBe();
  }

  pageChanged(event) {
    this.config.currentPage = event;
    this.canArtistsBe();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  canArtistsBe() {
    this.canShowSecondArtist = true;
    this.canShowThirdArtist = true;
    if (this.config.currentPage === (Math.floor(this.config.totalItems / this.config.itemsPerPage) + 1)) {
      if ((this.config.totalItems % this.config.itemsPerPage) === 2) {
        this.canShowSecondArtist = true;
        this.canShowThirdArtist = false;
      } else if ((this.config.totalItems % this.config.itemsPerPage) === 1) {
        this.canShowSecondArtist = false;
        this.canShowThirdArtist = false;
      } else if ((this.config.totalItems % this.config.itemsPerPage) === 0) {
        this.canShowSecondArtist = true;
        this.canShowThirdArtist = true;
      }
    }
  }
}
