import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-product-card-mobile',
  templateUrl: './product-card-mobile.component.html',
  styleUrls: ['./product-card-mobile.component.scss']
})
export class ProductCardMobileComponent implements OnInit {

  @Input() product: any;

  constructor() { }

  ngOnInit() {
  }

}
