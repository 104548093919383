import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthorizationService } from '../../services/authorization.service';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ProductModalComponent } from 'src/app/components/products/product-modal/product-modal.component';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  productList: Array<any>;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  modalInfo: any;

  listedNames: string[] = ['Products', 'Process', 'Artist', 'Events'];
  listIds: string[] = ['products_home_section', 'process_home_section', 'artist_home_section', 'event_home_section'];

  @ViewChild('products_section', { static: false }) products_section: ElementRef;
  @ViewChild(ProductModalComponent, { static: false }) productModal: ProductModalComponent;

  constructor(private authService: AuthorizationService, @Inject(DOCUMENT) document) {
    document.getElementById('navbar').classList.remove('sticky');
  }

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: '',
    prevArrow: '',
    dots: false,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1920,
        settings: { slidesToShow: 4, slidesToScroll: 2, infinite: false, dots: true }
      }, {
        breakpoint: 1024, settings: {
          slidesToShow: 2, slidesToScroll: 2, infinite: true, dots: true
        }
      }, {
        breakpoint: 600, settings: { 
          slidesToShow: 1, slidesToScroll: 1 
        }
      }, {
        breakpoint: 480, settings: {
          slidesToShow: 1, slidesToScroll: 1 
        } 
      } 
    ] 
  };

ngOnInit() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
  this.authService.get('wp-json/wp/v2/products?per_page=54')
    .subscribe(data => {
      this.storeData(data);
    });
}

@HostListener('window:scroll', ['$event'])
onWindowScroll(e) {
  if (this.products_section.nativeElement.getBoundingClientRect().top < 100) {
    const element = document.getElementById('navbar');
    element.classList.add('sticky');

    const homeSection = document.getElementById('home-section');
    homeSection.style.marginTop = '60px';
  } else {
    const element = document.getElementById('navbar');
    element.classList.remove('sticky');

    const homeSection = document.getElementById('home-section');
    homeSection.style.marginTop = '0';
  }
}

slickInit(e) {
}

breakpoint(e) {
}

afterChange(e) {
}

beforeChange(e) {
}

storeData(inputData: any) {
  this.productList = inputData.slice(0, 40).map(product => {
    return {
      title: product.title.rendered,
      productID: product.id,
      productCategory: product.acf.category,
      price: product.acf.price,
      mrp: product.acf.mrp,
      quantity: product.acf.quantity,
      colors: product.acf.color,
      imageURL: product.acf.display_image,
      imageList: this.getImages(Object.values(product.acf.images)),
      description: product.acf.description,
      info: product.acf.short_info
    };
  });
}


getImages(imageArray: any[]) {
  const images = [];
  imageArray.forEach((image) => {
    if (image !== false) {
      images.push(image);
    }
  });
  return images;
}

productClicked(item: any) {
  this.modalInfo = item;
  this.productModal.launchModal();
}



}
