import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  blogs: any[];
  config: any;

  constructor(
    private authService: AuthorizationService
  ) {
    document.getElementById('navbar').classList.add('sticky');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    authService.get('wp-json/wp/v2/blogs').subscribe(
      (data: any) => {
        this.storeBlogs(data);
      }
    );
  }

  ngOnInit() {
  }

  storeBlogs(input: any[]) {
    this.blogs = input.map((blog) => {
      return {
        blogId: blog.id,
        author: blog.acf.blog_author,
        date: blog.acf.blog_date,
        image1: blog.acf.blog_image_1,
        image2: blog.acf.blog_image_2,
        image3: blog.acf.blog_image_3,
        paragraph1: blog.acf.blog_paragraph_1,
        paragraph2: blog.acf.blog_paragraph_2,
        paragraph3: blog.acf.blog_paragraph_3,
        title: blog.acf.blog_title
      };
    });
    this.config = {
      itemsPerPage: 2,
      currentPage: 1,
      totalItems: this.blogs.length
    };
  }

  pageChanged(event) {
    this.config.currentPage = event;
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

}
