import { Component, OnInit, OnDestroy } from '@angular/core';


@Component({
  selector: 'app-footer-background',
  templateUrl: './footer-background.component.html',
  styleUrls: ['./footer-background.component.scss']
})
export class FooterBackgroundComponent implements OnInit, OnDestroy {
  images = ['../../../assets/img/footer1.png', '../../../assets/img/footer2.png', '../../../assets/img/footer3.png'];

  constructor() { }
  scroll = (): void => {
    const scrollPos = 1600;
    // adding scroll event
    const scrollTop = (document.body.getBoundingClientRect()).bottom;
    const translateX = (((-(scrollTop)) - scrollPos)) / 4;
    document.getElementById('imgAnimate').style.marginLeft = `${translateX}px`;
    document.getElementById('imgAnimate1').style.marginLeft = `${translateX}px`;
    document.getElementById('imgAnimate2').style.marginLeft = `${-(translateX + 480)}px`;


  }

  ngOnInit() {
    window.addEventListener('scroll', this.scroll, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

}
