import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-process-parllax-bg',
  templateUrl: './process-parllax-bg.component.html',
  styleUrls: ['./process-parllax-bg.component.scss']
})
export class ProcessParllaxBgComponent implements OnInit {

  @HostListener ('window: scroll')
  scrollHandler() {
    const yHeight =  document.getElementById('processParallaxBg').getBoundingClientRect().top;
    // tslint:disable-next-line: max-line-length
    document.getElementById('processParallaxBg').style.transform = 'translateY(' + ( - 100 - yHeight / 3) + 'px)';
    document.getElementById('parallaxStripChild1').style.transform = 'translateX(' + (yHeight / 8 + 300 ) + 'px)';
    document.getElementById('parallaxStripChild2').style.transform = 'translateX(' + (-100 - (yHeight / 6) ) + 'px)';
  }

  constructor() { }

  ngOnInit() {
  }

}
