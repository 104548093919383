import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  baseUrl = 'https://cms.kenjakura.com';
  mailUrl = 'https://mailer.bncmusical.co.in/sendmail';

  filter: any = new BehaviorSubject(null);
  constructor(private http: HttpClient) { }

  get(relativeUrl: string) {
    return this.http.get<any>(this.baseUrl + '/' + relativeUrl);
  }

  postWithBody(relativeUrl: string, body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Basic ' + btoa(unescape(encodeURIComponent('admin:datasutram')))
      })
    };
    return this.http.post<any>(this.baseUrl + '/' + relativeUrl, JSON.stringify(body), httpOptions);
  }

  postJWTWithBody(relativeUrl: string, body: any) {
    let JWTtoken: any;
    if (localStorage.getItem('token')) {
      JWTtoken = localStorage.getItem('token');
    } else {
      JWTtoken = 'hello world';
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: 'Bearer ' + JWTtoken
      })
    };
    if ( JWTtoken.length > 1 ) {
      return this.http.post(this.baseUrl + '/' + relativeUrl, JSON.stringify(body), httpOptions);
    }
  }

  sendMail(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.mailUrl, body, httpOptions);
  }

}
