import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  activeUrl: string;

  constructor(
    private authService: AuthorizationService,
    private router: Router
  ) {
    router.events.subscribe(
      (data: any) => { if (data.url) { this.activeUrl = data.url; } }
    );
  }

  ngOnInit() {
  }

  filterProduct(input?: string) {
    if (input) {
      this.authService.filter.next(input);
    } else {
      this.authService.filter.next('');
    }
  }

}
