import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-artist-mobile',
  templateUrl: './artist-mobile.component.html',
  styleUrls: ['./artist-mobile.component.scss']
})
export class ArtistMobileComponent implements OnInit {

  @Input() artist: any;

  constructor() { }

  ngOnInit() {
  }

}
