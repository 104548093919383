import { Component, OnInit } from '@angular/core';
import { faFacebook, faInstagram, faPinterest, faYoutube, faReddit } from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faDownload, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-floating-sidebar',
  templateUrl: './floating-sidebar.component.html',
  styleUrls: ['./floating-sidebar.component.scss']
})
export class FloatingSidebarComponent implements OnInit {
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faPinterest = faPinterest;
  faYoutube = faYoutube;
  faReddit = faReddit;
  faGlobe = faGlobe;
  faDownload = faDownload;
  faShoppingBag = faShoppingBag;

  brochures: any[];

  constructor(
    private _authService: AuthorizationService
  ) { }

  ngOnInit() {
    this.getBrochures();
  }

  getBrochures() {
    this._authService.get('wp-json/wp/v2/brouchures').subscribe((data: any[]) => {
      this.brochures = data.map((element) => {
        return {
          name: element.acf.name,
          brochure: element.acf.brochure
        }
      })
    })
  }

}
