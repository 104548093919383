import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-the-process',
  templateUrl: './the-process.component.html',
  styleUrls: ['./the-process.component.scss']
})
export class TheProcessComponent implements OnInit {

  // tslint:disable-next-line: max-line-length
  imgArray = ['../../assets/img/Artist_section_1.png', '../../assets/img/Artist_section_2.png', '../../assets/img/Artist_section_3.png', '../../assets/img/Artist_section_1.png', '../../assets/img/Artist_section_2.png', '../../assets/img/Artist_section_3.png'];
  // tslint:disable-next-line: max-line-length
  dotText = 'Dyeing in textiles is a process in which color is transferred to a finished textile or textile material (like fibers and yarns) to add permanent and long-lasting color.';
  // dotOrientation = 'left';
  animationStack = ['../../assets/img/1.jpg',  '../../assets/img/2.jpg', '../../assets/img/3.jpg'];

  constructor() { }

  ngOnInit() {
  }

}
