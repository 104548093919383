import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dot-scroller',
  templateUrl: './dot-scroller.component.html',
  styleUrls: ['./dot-scroller.component.scss']
})
export class DotScrollerComponent implements OnInit {
  @Input() names: string[] = [];
  @Input() ids: string[] = [];
   activeEvent(pos: any, id: string) {
     document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
     });
    // if (pos === 'dot1') {
    //   if (document.getElementById('dot1').className === 'dot dotOnClick') {
    //     document.getElementById('dot1').classList.remove('dotOnClick');
    //   } else {
    //     document.getElementById('dot1').classList.add('dotOnClick');
    //     document.getElementById('dot2').classList.remove('dotOnClick');
    //     document.getElementById('dot3').classList.remove('dotOnClick');
    //     document.getElementById('dot4').classList.remove('dotOnClick');
    //     window.scroll({
    //       top: 2500,
    //       left: 0,
    //       behavior: 'smooth'
    //     });
    //     // scroll certain amounts from current position
    //     window.scrollBy({
    //       top: 60, // negative value acceptable
    //       left: 0,
    //       behavior: 'smooth'
    //     });
    //     // scroll to a certain element
    //     document.querySelector('#dyeing').scrollIntoView({
    //       behavior: 'smooth'
    //     });
    //  }
    // }
    // if (pos === 'dot2') {
    //   if (document.getElementById('dot2').className === 'dot dotOnClick') {
    //     document.getElementById('dot2').classList.remove('dotOnClick');
    //   } else {
    //     document.getElementById('dot2').classList.add('dotOnClick');
    //     document.getElementById('dot1').classList.remove('dotOnClick');
    //     document.getElementById('dot3').classList.remove('dotOnClick');
    //     document.getElementById('dot4').classList.remove('dotOnClick');
    //     document.getElementById('sectionText1').style.transform = 'scale(1)';
    //     window.scrollBy({
    //     top: 0, // negative value acceptable
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    //   // scroll to a certain element
    //     document.querySelector('#animation').scrollIntoView({
    //       behavior: 'smooth'
    //     });

    //   }
    //  }
    // if (pos === 'dot3') {
    // if (document.getElementById('dot3').className === 'dot dotOnClick') {
    //   document.getElementById('dot3').classList.remove('dotOnClick');
    // } else {
    //   document.getElementById('dot3').classList.add('dotOnClick');
    //   document.getElementById('dot1').classList.remove('dotOnClick');
    //   document.getElementById('dot2').classList.remove('dotOnClick');
    //   document.getElementById('dot4').classList.remove('dotOnClick');

    // }
    // }
    // if (pos === 'dot4') {
    // if (document.getElementById('dot4').className === 'dot dotOnClick') {
    //   document.getElementById('dot4').classList.remove('dotOnClick');
    // } else {
    //   document.getElementById('dot4').classList.add('dotOnClick');
    //   document.getElementById('dot1').classList.remove('dotOnClick');
    //   document.getElementById('dot2').classList.remove('dotOnClick');
    //   document.getElementById('dot3').classList.remove('dotOnClick');


    // }
    // }
   }
  constructor() { }

  ngOnInit() {
  }

}
